import ClipLoader from 'vue-spinner/src/ClipLoader.vue';

export default {
    name: "PaymentForm",
    components: {
        ClipLoader,
    },
    computed: {
        signatureVersion() {
            // Should be HMAC_SHA256_V1
            const signatureVersion = this.$route.query.signatureVersion;
            // console.log("quesystring signatureVersion", signatureVersion);
            return signatureVersion;
        },
        merchantParameters() {
            const merchantParameters = this.$route.query.merchantParameters;
            // console.log("quesystring merchantParameters", merchantParameters);
            return merchantParameters;
        },
        signature() {
            const signature = this.$route.query.signature;
            // console.log("querystring signature", signature);
            return signature;
        },
        redsysUrl() {
            const redsysUrl = this.$route.query.redsysUrl;
            // console.log("querystring redsysUrl", redsysUrl);
            return redsysUrl;
        },
        test() {
            const test = this.$route.query.test;
            // console.log("querystring redsysUrl", redsysUrl);
            return test !== undefined && test === 'true';
        },
        allDataReady() {
            return this.signature !== undefined
                && this.signatureVersion !== undefined
                && this.merchantParameters !== undefined
                && this.redsysUrl !== undefined;
        }
    },
    mounted: function () {
        this.$nextTick(function () {
            // nextTick  se ejecutará solo después de haber renderizado la vista completa
            if (this.allDataReady === true && this.test === false) {
                // console.log("MOUNTED", this);
                // console.log("pForm", this.$refs.pForm);
                this.$refs.pForm.submit();
            } else {
                console.error('At least one of the necessary querystring parameters is missing');
            }
        });
    }
};
